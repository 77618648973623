(function() {
    window.hasPremium = false;

    window.checkPremium = function() {

        services.getPayments()
        .then(function (response) {
    
            for (let payment of response.data) {
    
                if (payment.expired == false) {
                    window.hasPremium = true;
                    return;
                }
    
            }
    
        });

        window.hasPremium = false;

    }

    window.plans = {
        'fa411f71-53d5-415a-ac2a-bf5e7f4d6cfc': {
            name: 'Day',
            info: [
                'Access for 1 day',
                'See all attractions',
                '10 waypoints per plan',
                '2 plans',
                'Open plans in Google Maps',
            ],
        },
        'af51adaa-5894-4bf4-8429-e52b93bb0f3f': {
            name: 'Basic',
            info: [
                'Access for 30 day',
                'See all attractions',
                '10 waypoints per plan',
                '5 plans',
                'Open plans in Google Maps',
            ],
        },
        '149af8d8-5cdf-4b6f-aead-7222f88ef30a': {
            name: 'Premium',
            info: [
                'Access for 30 day',
                'See all attractions',
                '10 waypoints per plan',
                '20 plans',
                'Open plans in Google Maps',
            ],
        }
    }

    window.utils = {
        rand(min, max) {
            return Math.floor(Math.random() * (max + 1)) + min;
        },
        hash(name) {
            let hash = window.location.hash.substr(1);
            let params = new URLSearchParams(hash);
            
            return params.get(name);
        },
        url: {
            params: function({url}) {
                let paramsObject = {};
                let params = url.split('?')[1].split('&');
                for (let param of params) {
                    let p = param.split('=');
                    paramsObject[`${p[0]}`] = p[1];
                }
                return paramsObject;
            },
            param: function({url, name}) {
                let params = utils.url.params({url: url});
                return params[`${name}`] ? params[name] : null;
            }
        },
        cookies: {
            has(name) {
                return !!this.get(name);
            },
            get(name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ')
                    c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0)
                    return decodeURI(c.substring(nameEQ.length, c.length));
                }
                return null;
            },
            set(name, value, days) {
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    var expires = "; expires=" + date.toGMTString();
                } else {
                    var expires = "";
                }
                //Adds the SameSite stuff for production. Adding it to dev breaks the dev site.
                let sameSite = window.location.hostname == 'localhost' ? '' : 'SameSite=None; Secure;';
                document.cookie = `${name}=${encodeURI(value) + expires}; ${sameSite} path=/`;
            },
            delete(name) {
                this.set(name, '', -1);
            },
            deleteAll() {
                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    this.set(name, '', -1);
                }
            }
        },
        validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        validatePassword(password) {
            var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/;
            return re.test(password);
        }
    }

})();